<template>
  <main>
    <h1 class="text-xl font-bold">Search for Business Details</h1>
    <div class="">
      <button class="bg-gray-800 py-2 px-3 rounded-md text-xs text-white mt-2" @click="$router.go(-1)">
        &laquo; Back
      </button>
    </div>
    <div>
      <div class="mt-10 border border-default px-5 py-8">
        <p>Enter Business Email</p>
        <form @submit.prevent="search" class="flex gap-2 items-center mt-1">
          <input type="email" class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm w-64 text-sm py-2"
            name="" placeholder="Search" id="" v-model="email" required />
          <button class="bg-blue-800 py-2 leading-6 px-6 rounded-md text-sm text-white" type="submit">
            Search
          </button>
          <button class="bg-red-800 py-2 leading-6 px-6 rounded-md text-sm text-white" @click="email = ''">
            Clear
          </button>
        </form>
      </div>
    </div>
    <div v-if="valid" class="mt-16 px-5 py-8 bg-blue-50">
      <div class="font-bold text-sm">{{ details.business_id }}</div>
      <div class="font-bold text-sm">{{ details.business_name }}</div>
      <div class="font-bold text-sm">{{ details.email }}</div>
      <button class="bg-blue-800 py-2 leading-6 px-6 rounded-md text-sm text-white block mt-3" @click="$router.push({
        name: 'VfdCreateNewLoanTwo',
        params: {
          id: details.business_id,
        },
      })
        ">
        Create Loan
      </button>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      valid: false,
      details: {
        business_id: "",
        business_name: "",
        email: "",
      },
    };
  },

  methods: {
    async search() {
      const response = await this.$store.dispatch(
        "WALLET_CREDITING_FETCH_SELECT_BUSINESS",
        {
          businessEmail: this.email,
        }
      );
      if (response.status) {
        const { business_id, business_name, email } = response.data;
        this.details = {
          business_id,
          business_name,
          email,
        };
        this.valid = true;
      } else {
        alert(response.message);
      }
    },
  },
};
</script>
